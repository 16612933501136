import { createI18n } from 'vue-i18n';
import zh from './zh';
import en from './en';

const messages = {
    en,
    zh
};
const language = (navigator.language || 'zh').toLocaleLowerCase(); // 这是获取浏览器的语言
const i18n = createI18n({
    //Not available in legacy mode 解决报错问题的配置项！！！
    legacy: false,
    locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh', // 首先从缓存里拿，没有的话就用浏览器语言，
    fallbackLocale: 'zh', // 设置备用语言 中文
    messages
});
export default i18n;
