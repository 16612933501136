export default {
    navBar: {
        homePage: 'HomePage',
        safetyTraining: 'SafetyTraining',
        virtualSimulation: 'VirtualSimulation',
        trainingBase: 'TrainingBase',
        safetyActivities: 'SafetyActivities',
        aboutUs: 'AboutUs',
        contactUs: 'ContactUs'
    },
    home: {
        clickToLearnMore: 'ClickToLearnMore',
        bannerContentTitle: 'One-stop safety culture service platform',
        bannerContentCon: 'System solution provider, fully enabling enterprise safety culture construction',
        productIntroduceTitle: 'Create a service platform for enterprise safety culture',
        productIntroduceDes: '"Industrial Internet + Safe Production"',
        safetyCompass: 'SafetyCompass',
        safetyCompassDes: 'New course + intelligent training platform',
        safetyTrainingBase: 'SafetyTrainingBase',
        safetyTrainingBaseDes: 'Five-dimensional safety skills training + production education training center',
        kunqiDigitalSecurity: 'KunqiDigitalSecurity',
        kunqiDigitalSecurityDes: 'Virtual simulation intrusive training',
        safetyActivities: 'SafetyActivities',
        safetyActivitiesDes: 'Gamified security learning + security big data analysis',
        productAdvantagesTitle: 'New options for safety training',
        productAdvantagesDes: 'Explore a new path for the transformation of enterprise safety culture construction',
        Specialization: 'Specialization',
        SpecializationDes: 'Focus on the construction of enterprise digital safety culture and provide high-quality professional services.',
        Individuation: 'Individuation',
        IndividuationDes: 'Tailor-made enterprise digital safety culture construction programs that meet actual needs to meet the individual needs of different users.',
        Advanced: 'Advanced',
        AdvancedDes: 'Adopt the latest virtual simulation technology, online education technology, multimedia teaching technology, etc., to maintain technical advancement.',
        Comprehensive: 'Comprehensive',
        ComprehensiveDes: 'Provide a full range of enterprise digital safety culture construction solutions. Timely and fast response to demand to ensure user satisfaction.',
        policyModuleTitle: 'Accurately and comprehensively solve user needs',
        policyModuleDes: 'Empower the construction of enterprise safety culture',
        ProceedFromTheSystem: 'Proceed from the system',
        ProceedFromTheSystemDes: 'Extract domestic and foreign excellent enterprise safety management experience, follow the international professional system',
        ImproveManagementTransparency: 'Improve management transparency',
        ImproveManagementTransparencyDes: 'Continuously improve the transparency of enterprise security management in all aspects, and the training effect is at a glance',
        HelpEmployeesGrow: 'Help employees grow',
        HelpEmployeesGrowDes: 'Effectively enhance staff safety awareness, emergency response ability, so that must learn to learn',
        dataEffectsTitle: 'Help enterprises improve quality, reduce cost and increase efficiency',
        dataEffectsDes: 'Make safety training smarter, more efficient and more convenient',
        ImprovingTrainingEfficiency: 'Training efficiency improvement',
        EmergencyResponseCapability: 'Staff emergency response ability improved',
        AbilityToIdentifyPotentialHazards: 'The ability of employees to detect hidden dangers is improved',
        IncreaseInTrainingParticipationRate: 'Training participation rate increased'
    }
};
