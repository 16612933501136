import { defineStore } from 'pinia';

const useMainStore = defineStore('main', {
    state: () => ({
        active: ''
    }),
    //开启持久化
    persist: {
        enabled: true,
        strategies: [{ storage: sessionStorage }]
    }
});

export default useMainStore;
