import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';
//i18n
import i18n from '@/utils/i18n';
//index.css
import '@/assets/css/index.css';
import 'animate.css';

// 视频插件
import vue3videoPlay from 'vue3-video-play'; // 引入组件
import 'vue3-video-play/dist/style.css'; // 引入css

import 'amfe-flexible';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.use(router).use(i18n).use(pinia).use(vue3videoPlay).mount('#app');
