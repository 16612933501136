export default {
    navBar: {
        homePage: '首页',
        safetyTraining: '安全培训',
        virtualSimulation: '虚拟仿真',
        trainingBase: '实训基地',
        safetyActivities: '安全活动',
        aboutUs: '关于我们',
        contactUs: '联系我们'
    },
    home: {
        clickToLearnMore: '点击了解',
        bannerContentTitle: '一站式安全文化服务平台',
        bannerContentCon: '系统解决方案供应商，全面赋能企业安全文化建设',
        productIntroduceTitle: '打造企业安全文化服务式平台',
        productIntroduceDes: '“工业互联网＋安全生产”',
        safetyCompass: '安全指南针',
        safetyCompassDes: '全新的课程+智能化培训平台',
        safetyTrainingBase: '安全实训基地',
        safetyTrainingBaseDes: '五维一体的安全技能实训+生产教育实训中心',
        kunqiDigitalSecurity: '鲲起数字安全',
        kunqiDigitalSecurityDes: '虚拟仿真侵入式培训',
        safetyActivities: '安全活动',
        safetyActivitiesDes: '游戏化安全学习+安全大数据分析',
        productAdvantagesTitle: '为安全培训提供新选择',
        productAdvantagesDes: '探索企业安全文化建设转型新路径',
        Specialization: '专业化',
        SpecializationDes: '专注企业数字化安全文化建设， 提供高品质专业化服务。',
        Individuation: '个性化',
        IndividuationDes: '量身定制符合实际需求的 企业数字化安全文化建设方案， 满足不同用户的个性化需求',
        Advanced: '先进化',
        AdvancedDes: '采用最新的虚拟仿真技术、 在线教育技术、多媒体教学技术等， 保持技术先进性。',
        Comprehensive: '综合化',
        ComprehensiveDes: '提供全方位的企业数字化 安全文化建设解决方案。 及时快速响应需求，保证用户满意度。',
        policyModuleTitle: '精准全面解决用户需求',
        policyModuleDes: '为企业安全文化建设赋能',
        ProceedFromTheSystem: '从体系出发',
        ProceedFromTheSystemDes: '萃取国内外优秀企业安全管理经验,沿用国际化专业体系',
        ImproveManagementTransparency: '提高管理透明度',
        ImproveManagementTransparencyDes: '全方位持续提升企业安全管理透明度，培训效果一览无余',
        HelpEmployeesGrow: '助力员工成长性',
        HelpEmployeesGrowDes: '有效提升员工安全意识、应急能力，让必学变乐学',
        dataEffectsTitle: '帮助企业提质降本增效',
        dataEffectsDes: '让安全培训更智能、更高效、更便捷',
        ImprovingTrainingEfficiency: '培训效率提升',
        EmergencyResponseCapability: '员工应急处置能力提升',
        AbilityToIdentifyPotentialHazards: '员工隐患排查能力提升',
        IncreaseInTrainingParticipationRate: '培训参与率提升'
    }
};
