/**
 * 检测元素是否滚动到可视范围
 * @param {*} el
 */
export const isElementNotInViewport = el => {
    if (el) {
        let rect = el.getBoundingClientRect();
        return rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0;
    }
};
