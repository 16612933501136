import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, onMounted, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ElConfigProvider } from 'element-plus';
import navBar from '@/components/navBar/nav-bar.vue';
import foot from '@/components/footer/footer.vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import { useI18n } from 'vue-i18n';
import { isElementNotInViewport } from '@/utils';
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    onMounted(() => {
      // 这是获取浏览器的语言
      const language = (navigator.language || 'zh').toLocaleLowerCase();
      // 首先从缓存里拿，没有的话就用浏览器语言，
      if ((localStorage.getItem('lang') || language.split('-')[0] || 'zh') == 'zh') {
        lang.value = zhCn;
      } else {
        lang.value = en;
      }
      window.addEventListener('scroll', () => {
        setAnimate(document.querySelectorAll('.animate-fadeInUp'));
        setAnimate(document.querySelectorAll('.animate-fadeInLeft'));
        setAnimate(document.querySelectorAll('.animate-fadeInRight'));
        setAnimate(document.querySelectorAll('.animate-zoomInDown'));
      });
    });
    watch(route, () => {
      setTimeout(() => {
        setAnimate(document.querySelectorAll('.animate-fadeInUp'));
        setAnimate(document.querySelectorAll('.animate-fadeInLeft'));
        setAnimate(document.querySelectorAll('.animate-fadeInRight'));
        setAnimate(document.querySelectorAll('.animate-zoomInDown'));
      }, 0);
    });
    const lang = ref(zhCn);
    const {
      locale
    } = useI18n();
    const setAnimate = el => {
      nextTick(() => {
        el.forEach(item => {
          if (!isElementNotInViewport(item)) {
            item.classList.remove('visibility-hidden');
          }
          // else {
          //     item.classList.add('visibility-hidden');
          // }
        });
      });
    };

    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass({
          'lang-en': _unref(locale) == 'en'
        }),
        style: {
          "height": "100%",
          "width": "100%"
        }
      }, [_createVNode(_unref(ElConfigProvider), {
        locale: lang.value
      }, {
        default: _withCtx(() => [_createVNode(navBar), _createVNode(_component_router_view), _createVNode(foot)]),
        _: 1
      }, 8, ["locale"])], 2);
    };
  }
};