import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        redirect: '/homePage'
    },
    {
        path: '/homePage',
        name: 'home',
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/safetyTraining',
        redirect: '/contentSystem',
        name: 'safetyTraining',
        component: () => import('@/views/safetyTraining/safetyTraining.vue'),
        children: [
            {
                path: '/contentSystem',
                name: 'contentSystem',
                component: () => import('@/views/safetyTraining/content-system.vue')
            },
            {
                path: '/coursewareFormat',
                name: 'coursewareFormat',
                component: () => import('@/views/safetyTraining/courseware-format.vue')
            },
            {
                path: '/managementPlatform',
                name: 'managementPlatform',
                component: () => import('@/views/safetyTraining/management-platform.vue')
            }
        ]
    },
    {
        path: '/virtualSimulation',
        name: 'virtualSimulation',
        component: () => import('@/views/virtualSimulation/virtualSimulation.vue')
    },
    {
        path: '/trainingBase',
        name: 'trainingBase',
        component: () => import('@/views/safetyTrainingBase/safetyTrainingBase.vue')
    },
    {
        path: '/safetyActivities',
        name: 'safetyActivities',
        component: () => import('@/views/safetyActivities/safetyActivities.vue')
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs/aboutUs.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.querySelector('html').scrollTop = 0;
    next();
});

export default router;
